var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.alarms && _vm.alarms.length)?[_c('SearchableTable',{key:_vm.maxResult,attrs:{"fields":_vm.fields,"items":_vm.orderedAlarms,"searchEnabled":false,"pagination":_vm.pagination,"maxResult":_vm.maxResult,"tableClass":"table table-condensed table-responsive"},scopedSlots:_vm._u([(!_vm.isEquipmentDashboard)?{key:"connector_name",fn:function(entry){return [_c('router-link',{staticClass:"cell-link clicable",attrs:{"title":_vm.$t('titles.access_dashboard'),"to":`/dashboard/equipment/${entry.item.connector_id}`}},[_vm._v(" "+_vm._s(entry.item.connector_name.toUpperCase())+" "),_c('i',{staticClass:"fa fa-dashboard"})])]}}:null,{key:"name",fn:function(entry){return [_c('span',[_vm._v(_vm._s(entry.item.name.toUpperCase()))])]}},{key:"status",fn:function(entry){return [(entry.item.alarm_current_state.state)?_c('span',{staticClass:"alarm-state"},[_c('i',{staticClass:"fa fa-exclamation-triangle"}),_vm._v(" "+_vm._s(_vm.$t("alarmed"))+" ")]):_c('span',{staticClass:"alarm-state"},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" "+_vm._s(_vm.$t("normal"))+" ")])]}},{key:"lastTransition",fn:function(entry){return [_c('span',[_vm._v(_vm._s(_vm.$dt.format(entry.item.lastTransition)))])]}},{key:"ackStatus",fn:function(entry){return [(entry.item.ackStatus != '_unknown')?_c('button',_vm._b({staticClass:"btn btn-xs btn-default large-text no-wrap",class:!entry.item.enabled ? 'ack_disabled' : entry.item.ackStatus,attrs:{"type":"button","data-testid":"alarm-ack","disabled":_vm.ack_status[entry.item.ackStatus].disabled || !entry.item.enabled},on:{"click":function($event){return _vm.acknowledgementButtonClicked(entry.item)}}},'button',
            entry.item.enabled
              ? { title: _vm.$t(_vm.ack_status[entry.item.ackStatus].title) }
              : {}
          ,false),[_c('i',{staticClass:"fa",class:_vm.ack_status[entry.item.ackStatus].icon})]):_vm._e()]}},{key:"current_value",fn:function(entry){return [(entry.item.data)?_c('DataValueDisplay',{key:`${entry.item.id}${
              entry.item.data.pending_command ||
              entry.item.data.pending_mapping_write
                ? 'p'
                : 'u'
            }`,attrs:{"entry":entry.item.data,"zeroAsDash":false},on:{"commandRemoved":_vm.commandRemoved}}):_c('span',[(!_vm.dataList || !_vm.dataList.length)?_c('i',{staticClass:"fa fa-refresh fa-spin"}):_vm._e()])]}},{key:"criteria_order",fn:function(entry){return [_c('div',{staticClass:"alarm-condition"},[_vm._v(" "+_vm._s(entry.item.condition)+" "),(entry.item.data)?_c('DataValueDisplay',{key:`${entry.item.id}${
                entry.item.data.pending_command ||
                entry.item.data.pending_mapping_write
                  ? 'p'
                  : 'u'
              }`,staticStyle:{"width":"auto"},attrs:{"title":`${entry.item.limit} ${entry.item.data.unity_label || ''}`,"entry":entry.item.limitData,"zeroAsDash":false}}):_vm._e()],1)]}},{key:"level",fn:function(entry){return [_c('AlarmLevelDisplay',{attrs:{"alarm":entry.item}})]}},{key:"ack_enabled",fn:function(entry){return [(entry.item.acknowledgment_enabled)?_c('span',[_vm._v(_vm._s(_vm.$tc("enabled")))]):_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$tc("disabled")))])]}},{key:"notification",fn:function(entry){return [_c('button',{staticClass:"btn btn-xs btn-default large-text",attrs:{"type":"button","title":_vm.$t('notification')},on:{"click":function($event){return _vm.notificationButtonClicked(entry.item)}}},[_c('i',{staticClass:"fa fa-share-square"})])]}},{key:"configure",fn:function(entry){return [_c('button',{staticClass:"btn btn-xs btn-default large-text",attrs:{"type":"button","title":_vm.$t('hints.access_to_configuration_form')},on:{"click":function($event){return _vm.edit(entry.item)}}},[_c('i',{staticClass:"fa fa-wrench"})])]}}],null,true)})]:[_vm._t("no_alarms",function(){return [_c('div',{staticClass:"alert text-center",staticStyle:{"background-color":"whitesmoke","margin-top":"25px"}},[_vm._v(" "+_vm._s(_vm.$t("there_are_no_alarms_configuration"))+" ")])]})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }